<template>
  <v-container>
    <div v-if="apiError">Ошибка загрузки данных</div>
    <div v-if="dataLoaded">

      <div class="employe-card">

        <h1>
          <span>{{ employee.surname | df }} {{ employee.name | df }} {{ employee.middleName }}</span>
        </h1>

        <div class="badge">{{ employee.inState ? 'Штатный' : 'Внештатный' }}</div>
        {{ fullYears() | df }} {{ godOrLetStr(fullYears()) }} / Дата рождения {{ employee.birthday | formatDate }}

        <div class="tabs-wrap">
          <div class="buttons">
            <v-btn @click="$router.push('/edit-employee/' + employee.id)">Редактировать</v-btn>
          </div>


          <div class="link-tabs">
            <div>
              <router-link :to="'/employee/'+ employee.id + '/info'">Информация</router-link>
              <router-link :to="'/employee/'+ employee.id + '/files'" v-if="userInOneOfRoles('ROLE_FORMAL')">Документы</router-link>
            </div>
          </div>

          <router-view :employee="employee" :container-id="employee.fileStorageContainerId"
                       :nav-parent="'/employee/' + employee.id + '/files'"></router-view>

        </div>

      </div>
    </div>
  </v-container>
</template>

<script>
import api from '../../modules/api'
import {userInOneOfRoles} from "@/modules/CommonUtils";

export default {
  name: 'EmployeeComponent',
  props: {},
  data() {
    return {
      apiError: false,
      dataLoaded: false,
      employee: {},
    };
  },
  methods: {
    userInOneOfRoles,
    fullYears() {
      if (this.employee.birthday == null)
        return undefined
      const today = new Date()
      const curYear = today.getFullYear();
      const birthday = new Date(this.employee.birthday)
      const birthYear = birthday.getFullYear()
      const curYearBirthdayDate = new Date(this.employee.birthday).setFullYear(curYear)
      return today >= curYearBirthdayDate ? curYear - birthYear : curYear - birthYear - 1
    },
    godOrLetStr(age) {
      let txt
      let count = age % 100
      count >= 5 && count <= 20 ? txt = 'лет' : count = count % 10, count == 1 ? txt = 'год' : (count >= 2 && count <= 4) ? txt = 'года' : txt = 'лет'
      return txt
    }
  },
  async beforeMount() {
    let id = this.$route.params.id
    if (id == null) {
      this.apiError = true
      return
    }
    let req1 = await api.get("/supmain/experts/" + id)
    if (req1.ok) {
      this.employee = req1.payload
      this.dataLoaded = true
    } else {
      this.dataLoaded = true
    }
  }
}
</script>
