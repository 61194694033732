<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <EmployeeComponent/>

    </transition>
  </div>
</template>

<script>

import EmployeeComponent from '@/components/employees/EmployeeComponent.vue'

export default {
  name: 'Employee',
  components: {
    EmployeeComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
